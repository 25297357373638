import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes = [
  { path: '/', name: 'home', component: HomeView },
  { path: '/about', name: 'about', component: () => import('../views/AboutView.vue') },
  { path: '/login', name: 'LoginView', component: () => import('../views/LoginView.vue') },
  { path: '/register', name: 'register', component: () => import('../views/SignupView.vue') },
  { path: '/forgot-password', name: 'ForgotPassView', component: () => import('../views/ForgotPassView.vue') },
  { path: '/reset-password', name: 'ResetPassView', component: () => import('../views/ResetPassView.vue') },
  { path: '/create', name: 'zedart', component: () => import('../views/ZedArtView.vue') },
  { path: '/chat', name: 'baymax', component: () => import('../views/BayMaxView.vue') },
  { path: '/feed', name: 'FeedView', component: () => import('../views/FeedScreen.vue') },
  { path: '/profile', name: 'ProfileView', component: () => import('../views/ProfileView.vue') },
  { path: '/explore', name: 'ExploreView', component: () => import('../views/ExploreView.vue') },
  { path: '/pro', name: 'SubscriptionPlans', component: () => import('../views/ProView.vue') },
  { path: "/@:username", name: "UserView", component: () => import('../views/UserView.vue') },
  { path: "/post/:slug", name: "PostPreview", component: () => import('../views/PostPreview.vue') }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// Add navigation guard
router.beforeEach((to, from, next) => {
  const accessToken = localStorage.getItem('access_token');
  const publicPages = ['/', '/about', '/login', '/register', '/forgot-password', '/reset-password'];
  const authPage = publicPages.includes(to.path);

  if (accessToken && authPage) {
    next('/feed');
  } else {
    // Proceed normally
    next();
  }
});

export default router;
